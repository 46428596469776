import React from 'react';
import { Fade , Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image7 from '../../images/group.png';
import Image8 from '../../images/www.png';
import Image6 from '../../images/router1.png';
import Image9 from '../../images/line2.png';
import Image10 from '../../images/security1.png';
import Image11 from '../../images/4.png';

export default function Home(){
  return (
    <div>
    <body id="default_theme" class="home_page1">
      <div className="slide-container">
      <Fade>
        <div className="each-fade">
          <div className="image-container" >
            <img src={Image11} style={{maxWidth:"100%", height:"auto"}} alt="#"/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container" >
            <img src={Image9} style={{maxWidth:"100%", height:"auto"}} alt="#"/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container" >
            <img src={Image10} style={{maxWidth:"100%", height:"auto"}} alt="#"/>
          </div>
        </div>
      </Fade>
    </div>
      <section class="layout_padding layer_style">
         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <div class="full text_align_center">
                     <div class="heading_main center_head_border heading_style_1">
                        <h2>WelCome to <span>SUPER</span></h2>
                     </div>
                  </div>
               </div>
            </div> 
            <ul>
            <li>We at, Super, very happy to announce launch of Internet Broadband Services on 
                  Underground Optic Fibre integrated with World-class Wireless 4G capable Base stations 
                  & CPE's and controlled by deploying world-class best companies <b>SERVERS, NAS & ROUTERS 
                  at NETWORK OPERATION CENTRE</b> with 24*7 Helpdesk facility for supporting customers.</li>
            </ul>
         </div>
         <div class="container" style={{marginTop:"5%"}}>
         <div class="row">
         <div class="col-md-4">
            <div class="information_blogs">
               <div class="imf_icon"><img src={Image6} alt="#" /></div>
                  <div class="imf_head">
                     <h3>High Speed Internet</h3>
                  </div>
                  <div class="imf_cont">
                     <b style={{color:"#000000",fontweight:"45px"}}>Customer First Approach:</b>
                     <ul>
                     <li class="w3-animate-bottom">Every person in the Super is having Customer First 
                     approach. Customer is the reason for our existence. We at Airlink are committed to 
                     fulfill the requirement of customer, by designing plans suits their needs and purpose.</li>
                     </ul>
                  </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="information_blogs">
               <div class="imf_icon"><img src={Image7} alt="#" /></div>
                  <div class="imf_head">
                     <h3>What we are</h3>
                  </div>
                  <div class="imf_cont">
                     <ul>
                     <li class="w3-animate-bottom">Dark fiber is optical fiber infrastructure that is in place but not presently being 
                        utilized by its owner. In the simplest terms, it is another name for optical glass 
                        fibers that have been installed underground, but do not have any electronics or 
                        lasers attached. </li>
                     </ul>
                  </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="information_blogs">
               <div class="imf_icon"><img src={Image8} alt="#" /></div>
                  <div class="imf_head">
                     <h3>What we do</h3>
                  </div>
                  <div class="imf_cont">
                  <ul>
                     <li class="w3-animate-bottom">Super have deployed multiple numbers of HDPE ducts in entire underground 300 KMs 
                     Network. We have laid our OFC Network in one duct. Remaining ducts we have shelled 
                     with end cap plug in every chamber.</li>
                  </ul>
                  </div>
            </div>
         </div>
         </div>
         </div>
      </section>
      <section class="layout_padding">
         <div class="container">
            <div class="row">
               <div class="full">
                  <div class="row">
                     <div class="col-sm-12">
                        <div class="full text_align_center">
                           <div class="heading_main center_head_border heading_style_1">
                              <h2>Table price</h2>
                              <Carousel>
                              <Slide>
                              <div className="each-fade">
                                    <div className="image-container">
                                       <h4>Only Intercom Monthly Rs.125/- & Yearly Rs.1200/- </h4>
                                    </div>
                                 </div>
                                 <div className="each-fade">
                                    <div className="image-container">
                                       <h4>Pay Advance 6 Months & 1 Month Free only ₹599/-</h4>
                                    </div>
                                 </div>
                                 <div className="each-fade">
                                    <div className="image-container">
                                       <h4>Pay Advance 12 Months & 3 Months Free only ₹599/-</h4>
                                    </div>
                                 </div>
                              </Slide>
                              </Carousel>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-12">
                  <div class="full" style={{margintop:'10px',marginBottom:'10px'}}>
                     <div class="row">
                     <div class="col-sm-12 col-md-3">
                           <div class="active_price table_price text_align_center">
                              <div class="table_price_head">
                                 <h5>Basic</h5>
                              </div>
                              <div class="table_price_cont">
                                 <div class="table_price_per">
                                    <p>Rs 135.00<br />
                                       <small>/month</small>
                                    </p>
                                 </div>
                                 <div class="table_price_cont_bottm">
                                    <ul>
                                       <li>Free</li>
                                       <li>Rs 50 use In GUJARAT</li>
                                       <li>at any Network</li>
                                    </ul>
                                 </div>
                              </div>
                              {/* <div class="table_price_bottm"> <a class="reverse_bt bt_main" href="index.html" >Buy now</a> </div> */}
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                           <div class="table_price text_align_center">
                              <div class="table_price_head">
                                 <h5>Normal</h5>
                              </div>
                              <div class="table_price_cont">
                                 <div class="table_price_per">
                                    <p>Rs 299.00<br />
                                       <small>/month</small>
                                    </p>
                                 </div>
                                 <div class="table_price_cont_bottm">
                                    <ul style={{marginBottom:"-10%"}}>
                                       <li>200 min Mobile</li>
                                       <li>All LandLine calling Free</li>
                                       <li>In GUJARAT</li> 
                                       <li>at any Network</li>
                                    </ul>
                                 </div>
                              </div>
                              {/* <div class="table_price_bottm"> <a class="bt_main" href="index.html" >Buy now</a> </div> */}
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                           <div class="active_price table_price text_align_center">
                              <div class="table_price_head">
                                 <h5>Standard</h5>
                              </div>
                              <div class="table_price_cont">
                                 <div class="table_price_per">
                                    <p>Rs 449.00<br />
                                       <small>/month</small>
                                    </p>
                                 </div>
                                 <div class="table_price_cont_bottm">
                                    <ul style={{marginBottom:"-4%"}}>
                                       <li>Free</li>
                                       <li>All GUJARAT Unlimited calling</li>
                                       <li>at any Network</li>
                                    </ul>
                                 </div>
                              </div>
                              {/* <div class="table_price_bottm"> <a class="reverse_bt bt_main" href="index.html" >Buy now</a> </div> */}
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                           <div class="table_price text_align_center">
                              <div class="table_price_head">
                                 <h5>Profesional</h5>
                              </div>
                              <div class="table_price_cont">
                                 <div class="table_price_per">
                                    <p>Rs 599.00<br />
                                       <small>/month</small>
                                    </p>
                                 </div>
                                 <div class="table_price_cont_bottm">
                                    <ul style={{marginBottom:"-4%"}}>
                                       <li>Free</li>
                                       <li>All INDIA Unlimited calling</li>
                                       <li>at any Network</li>
                                    </ul>
                                 </div>
                              </div>
                              {/* <div class="table_price_bottm"> <a class="bt_main" href="index.html">Buy now</a> </div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

    </body>
    </div>
  );
}
