import React from 'react';
import Image1 from '../../images/about.jpg';
import Image2 from '../../images/spears.png';
import Image3 from '../../images/voip.png';
import Image4 from '../../images/vpn.png';
import Image5 from '../../images/video.png';
// import { Container } from './styles';

export default function About(){
  return (
    <div>
     <body id="default_theme" class="about">
      <section id="banner_parallax" class="inner_page_banner slide_banner1">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="full">
                     <div class="slide_cont">
                        <h2>About US</h2>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="layout_padding layer_style">
        <div class="container">
          <div class="row"  class="w3-container w3-animate-left">
            <ul style={{textalign:"left",padding:"1%"}}>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> We at, SUPER, very happy to announce launch of Internet Broadband Services on Underground Optic Fibre integrated with World-class Wireless 4G capable Base stations & CPE's and controlled by deploying world-class best companies <span>SERVERS, NAS & ROUTERS</span> at NETWORK OPERATION CENTRE with 24*7 Helpdesk facility for supporting customers.</li>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> Super is engaged in building the next generation Wireless Broadband Services integrated with Underground Optical Fibre for Residential, SME and Corporate customers.</li>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> The unprecedented development in IT and Telecom has significantly changed the way business and operations are done. At the same time the technological development has created lot of business opportunities that were impossible just a few years ago. So deep is the impact of IT on various aspects of operation, that for Example the government functioning, which was characterized many times as slow moving and non-transport, is also changing its face. The classic example is the Government of Gujarat, which has taken revolutionary step to create IT Infrastructure to increase effectiveness of Governance, thereby serving people better.</li>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> Super is entering in to business with ambitious strategic plan which will revolutionize this growth further. We are planning to offer always on high speed data Internet services deploying world-class technologies available under the brand name “Super Broadband”.</li>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> Super have strategic alliance with award winning EION Inc., world Leader Company in wireless & has dominance in bringing 16.d wimax wireless technology which will give you link oping news.</li>
              <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> Super has a Network capable of providing Data and Internet Services, having its own underground Optical Fiber Network across the South Gujarat. The company will cater to all SME and Corporates requirement of networking (WAN) and Internet Bandwidth requirements of the domestic enterprise market. SUPER offers quick loans no check and state-of-the-art Managed Network Services to enterprises across all verticals.</li>
             </ul>
          </div>
         </div>
         <div class="container">
         <div class="row">
            <div class="col-md-6">
               <img src={Image1} alt="#" />
            </div>
            <div class="col-md-6">
               <ul>
               <li style={{margin:"1%"}}>Metro Ethernet Network (MEN) requires close monitoring along with deployment of significant
                  resources for the monitoring of the Network, to achieve required Service Level Agreement 
                  (SLA). This resource includes: </li>
               </ul>
               <ul>
                  <li><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i>  Efficient Network Monitoring System.</li>
                  <li><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i>  Call Centre for Customer Interface.</li>
                  <li><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i>  Skilled Manpower to look after all technical and operational aspects.</li>
                  <li><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i>  Strong presence of the Operator in the region where the Network is deployed.</li>
               </ul>
            </div>
         </div>
         </div> 
      </section>    
      <section class="layout_padding layer_style">
         <div class="container">
            <div class="row">
               <div class="col-sm-12" class="w3-container w3-animate-bottom">
               <h4 style={{color:"#800000"}}>NOC for MEN</h4>
               <ul>
               <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> SUPER has given in-depth considerations to monitoring and operations aspects of the Network. 
                  Network Management is one of the modern tools available to AIRLINK to efficiently control,
                   maintain, configure and operate the entire Network with our site, Check out loanquickvalue.com.
                  NMS System of all three equipment viz., Network Nodes, Routers and automated IVRS has been 
                  installed at Network Operation Centre (NOC) at Surat. AIRLINK has Helpdesk to respond to 
                  inquiries from users. People with different skill set manage NMS and call centre 24 hours 
                  a day, 7 days a week basis.</li>
               <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> SUPER will be a ONE STOP SOLUTION, for meeting end to end IT requirements of any 
                  SME/Enterprise/Corporate customers. With so many years of experience of creating robust 
                  infrastructure, networking needs of domestic market & having huge experience of catering 
                  services in IT service Industry from one decade; now SUPER is all set to emerge as a leader 
                  in the domestic network services market. Our Senior most people in company have provided 
                  solutions for Surveillance, Mass Emailing, Internet Services, Wireless Solutions to various 
                  Segments like Textile and Diamond in Surat, FMCG, IT, ITES, Manufacturing units, 
                  Pharmaceutical and all other multi-locational Industries in Vapi, Daman, Silvassa, Umargaon,
                  Dahej, Ankleshwar and Panoli, which is named “GOLDEN CORRIDOR OF INDIA”.</li>
               <li style={{padding:"1%"}}><i class="fa fa-angle-double-right" style={{color:"#800000"}}></i> Super operates on a Metro Ethernet Network (MEN). The Network created by SUPER is fully 
                  redundant & built on carrier class architecture, which enables to deliver high uptime for 
                  NETWORK. Each of our POP is equipped to provide all the latest WAN protocols. SUPER has 
                  engaged the best-in-class technology partners and work closely with PDN financiers to 
                  build the required capability frameworks.</li>
               </ul>
               </div>
            </div>
            </div>
      </section>
      <section>
            <div class="container">
            <div class="row">
            <div class="col-md-6">
               <div><img src={Image2} alt="#" /></div>
                  <div >
                     <h3>Spares</h3>
                     <ul>
                     <li>In order to be able to make faulty equipment serviceable, SUPER is stocking a sufficient
                         number of spares. These spare shall be deployed at NOC and at each Zonal Centres.</li>
                     </ul>
                  </div>
            </div>
            <div class="col-md-6">
               <div style={{marginLeft:"9%"}}><img src={Image3} alt="#" /></div>
                  <div>
                     <h3 style={{marginLeft:"9%"}}>VoIP (Voice Over IP)</h3>
                     <ul style={{marginLeft:"4%"}}>
                     <li>Hotline Full VoIP connectivity.</li>
                     </ul>
                  </div>
            </div>
            </div>
         </div>
         <div class="container">
            <div class="row">
            <div class="col-md-6">
               <div><img src={Image4} alt="#" /></div>
                  <div>
                     <h3>VPN Services</h3>
                     <ul>
                     <li>LAN Connectivity</li>
                     <li>Internet Access</li>
                     </ul>
                  </div>
            </div>
            <div class="col-md-6">
               <div style={{marginLeft:"9%"}}><img src={Image5} alt="#" /></div>
                  <div>
                     <h3 style={{marginLeft:"16%"}}>Video</h3>
                     <ul style={{marginLeft:"5%"}}>
                     <li>Video Conferencing</li>
                     </ul>
                  </div>
            </div>
         </div>
         </div>
      </section>
    </body>
    </div>
  );
}
