import './App.css';
import Home from './Component/Home/Home';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import About from './Component/About/About';
import Header from './Defult/Header';
import Footer from './Defult/Footer';
import Plan from './Component/Plan/Plan';
import Contactus from './Component/Contactus/Contactus';

function Routing() {
  return(
    <BrowserRouter>
    <Switch>
      <Route path='/' exact component={Home}></Route>
      <Route path='/about' component={About}></Route>
      <Route path='/plan' component={Plan}></Route>
      <Route path='/contactus' component={Contactus}></Route>
    </Switch>
    </BrowserRouter>
  )
  
}

function App() {
  return (
    <div className="App">
      <Header />
      <Routing />
      <Footer />
    </div>
  );
}

export default App;
