import React from 'react';

// import { Container } from './styles';

export default function Contactus(){
  return (
    <div>
    <body id="default_theme" class="home_page1">
      <section id="banner_parallax" class="slide_banner1">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="full">
                     <div class="slide_cont">
                        <h2>Contact US</h2>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section>
      <div class="row">
         <div class="col-md-6">
         <iframe width="600" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=Mahidharpura,%20Haripura,%20Surat,%20Gujarat%20395008&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="branchoffice"></iframe>
         </div>
         <div class="col-md-6">
            <div style={{marginTop:"2%",padding:"3%"}}>
               <div style={{marginBottom:"2.5%"}}>
               <h4 style={{color:"#800000"}}>Super Telecom</h4>
               <h4 style={{color:"#800000"}}>Super Infratel</h4>
               <h4 style={{color:"#800000"}}>Ratna Telnet-Group</h4>
               </div>
                <p style={{color:"#000000",fontSize:"18px",fontFamily:"lato"}}><i class="fa fa-location-arrow" style={{color:"#800000" ,marginRight:"10px"}}></i>Dalagiya Moholo, Daimond Market, Mahidharpura, Surat, Gujarat 395008</p>
                <p style={{color:"#000000",fontSize:"18px",fontFamily:"lato"}}><i class="fa fa-phone" style={{color:"#800000",marginRight:"10px"}}></i>+91-261-2609000, +91-261-2608000</p>
                <p style={{color:"#000000",fontSize:"18px",fontFamily:"lato"}}><i class="fa fa-mobile"  style={{color:"#800000",marginRight:"15px"}}></i>+91-9099009152</p>
                <p style={{color:"#000000",fontSize:"18px",fontFamily:"lato"}}><i class="fa fa-whatsapp" style={{color:"#800000",marginRight:"10px"}}></i>+91-9374609000</p>
                <p style={{color:"#000000",fontSize:"18px",fontFamily:"lato"}}><i class="fa fa-envelope" style={{color:"#800000",marginRight:"10px"}}></i>support@superinfratel.com</p>
            </div>
         </div>
      </div>
      </section>
      <section class="layout_padding layer_style">
        <div class="container">
          <div class="row">
            <h4 style={{color:"#800000"}}>Prons:</h4>
            <ul style={{textalign:"left",padding:"1%"}}>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>All complaints will be resolved within 3(Three) days of receipt.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>“In case the problem is not resolved within the stipulated time as given by the Call Centre, or the customer is not satisfied with the resolution provided he/she may escalate the complaint to the Nodal officer.”</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>“In case the customer is not satisfied with the redressal of his grievance by the nodal officer or his complaint remains to be redressed or no reply is received within the abovementioned specified period, such consumer may, in writing, make an appeal to the Appellate Authority for redressal of his grievance. Every appeal to the Appellate Authority shall be made in duplicate, in the form attached hereto. Every such appeal shall be filed within 3 months after the expiry of the time limit of 10 days.”</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>"Provided that the Appellate Authority may entertain any appeal after the expiry of the three months but before 1 year from the time limit specified if it is satisfied that there was sufficient cause for not filing it within that period.”</li>
             </ul>
          </div>
         </div>
      </section>
    </body>
    </div>
  );
}
