import React from 'react';
import Image1 from '../images/s2.png';

// import { Container } from './styles';

export default function Defult(){
  return (
    <div>
        <header class="header header_style1">
         <div class="container">
            <div class="row">
               <div class="col-md-9 col-lg-12">
                  <div class="logo"><a href="index.html"><img src={Image1} alt="#" /></a></div>
                  <div class="main_menu float-right">
                     <div class="menu">
                        <ul class="clearfix">
                           <li class="active"><a href='/'>Home</a></li>
                           <li><a href='/about'>About</a></li>
                           <li><a href="/plan">Plan</a></li>
                           <li><a href="/contactus">Contact US</a></li>
                        </ul>
                     </div>
                  </div>
               </div>
               {/* <div class="col-md-3 col-lg-2">
                  <div class="right_bt"><a class="bt_main" href="index.html">Get Support</a> </div>
               </div> */}
            </div>
         </div>
      </header>
    </div>
  );
}
