import React from 'react';

// import { Container } from './styles';

export default function Plan(){
  return (
    <div>
      <body id="default_theme" class="about">
      <section id="banner_parallax" class="inner_page_banner slide_banner1">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="full">
                     <div class="slide_cont">
                        <h2>Tariffs plans</h2>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="layout_padding layer_style">
      <div>
         <div class="container" style={{marginTop:"2%"}}>
         <div class="row">
         <table class="customers">
            <tr>
               <th rowSpan="2" style={{textAlign:"center"}}>Package Name</th>
               <th rowSpan="2" style={{textAlign:"center"}}>Speed</th>
               <th colSpan="4" style={{textAlign:"center"}}>Validity / Package Cost (`)</th>
               <th rowSpan="2" style={{textAlign:"center"}}>Installation Charge</th>
            </tr>
            <tr>
               <th style={{textAlign:"center"}}>12 Months</th>
               <th style={{textAlign:"center"}}>6 Months</th>
               <th style={{textAlign:"center"}}>3 Months</th>
               <th style={{textAlign:"center"}}>1 Month</th> 
            </tr>
            <tr>
               <td colSpan="7" style={{backgroundColor:"rgb(188, 25, 31)",color:"white",fontWeight:"bold"}}>Unlimited Plan</td>
            </tr>
            <tr>
               <td>20MBPS</td>
               <td>20mbps</td>
               <td>4750</td>
               <td>-</td>
               <td>-</td>
               <td>-</td>
               <td>399/-</td>
            </tr>
            <tr>
               <td>25MBPS</td>
               <td>25mbps</td>
               <td>5250</td>
               <td>-</td>
               <td>-</td>
               <td>-</td>
               <td>399/-</td>
            </tr>
            <tr>
               <td>40MBPS</td>
               <td>40mbps</td>
               <td>5850</td>
               <td>3250</td>
               <td>1850</td>
               <td>1199</td>
               <td>399/-</td>
            </tr>
            <tr>
               <td>60MBPS</td>
               <td>60mbps</td>
               <td>6850</td>
               <td>3750</td>
               <td>2050</td>
               <td>1299</td>
               <td>399/-</td>
            </tr>
            <tr>
               <td>75MBPS</td>
               <td>75mbps</td>
               <td>8050</td>
               <td>4750</td>
               <td>3150</td>
               <td>1399</td>
               <td>399/-</td>
            </tr>
            <tr>
               <td colSpan="7" style={{backgroundColor:"rgb(188, 25, 31)",color:"white",fontWeight:"bold"}}>FUP Plans</td>
            </tr>
            <tr>
               <td>Fup-301GB/50MBPS</td>
               <td>50mbps/10mbps</td>
               <td>5350</td>
               <td>3100</td>
               <td>1850</td>
               <td>-</td>
               <td>-</td>
            </tr>
            <tr>
               <td>Fup-400GB/60MBPS</td>
               <td>60mbps/15mbps</td>
               <td>5750</td>
               <td>3300</td>
               <td>1950</td>
               <td>-</td>
               <td>-</td>
            </tr>
            <tr>
               <td>Fup-701GB/75MBPS</td>
               <td>75mbps/25mbps</td>
               <td>6350</td>
               <td>3600</td>
               <td>2100</td>
               <td>-</td>
               <td>-</td>
            </tr>
         </table>
         </div>
         </div>
         </div>
      </section>
      <section class="layout_padding layer_style">
        <div class="container">
          <div class="row">
            <h4 style={{color:"#800000"}}>Terms & Conditions:</h4>
            <ul style={{textalign:"left",padding:"1%"}}>
              <h5>Note: Taxes extra at actual.</h5>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Router is available on refundable deposit.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Frame Static IP (Dialer based) available @ `.150/- per Month. (available with plan validity/balance validity only)</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>All packages are Pre-paid and no refund will be given in any case.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>In normal circumstances, the expected time for a new installation is 5 working days after technical feasibility is done.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Renewal activation time will be 6 hrs. after submission of CRF with payments at the office.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Installation Charge is 250/- extra. one-time Installation Charge for a 1 Month Plan. Installation Charge is non-refundable in any event.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>75 Mbps plan available only in Mahidharpura areas.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Shifting Charges for Wired connection(Mahidharpura to Mahidharpura) is Rs.500/-. Shifting charges for Wireless connection(Other Area) are Rs.1000/-. Shifting is subject to technical feasibility at the proposed new location.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Internet Security is Subscribers responsibility and all Customers are advised to take necessary security precautions.</li>
              <li><i class="fa fa-angle-double-right" style={{color:"#800000",margin:"1%"}}></i>Customer will have to provide permission for installation of Junction Box on his premise and will have to provide an uninterpreted power supply for the same.</li>
            </ul>
          </div>
         </div>
      </section>
      </body>
    </div>
  );
}

