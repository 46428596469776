import React from 'react';

// import { Container } from './styles';

export default function Defult(){
  return (
    <div>
        <footer>
         <div class="footer_top" style={{backgroundColor:"#383838"}}>
            <div class="container">
               <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 margin_bottom_30">
                     <div class="full width_9" style={{marginTop:"25px"}}>
                        <p p style={{color:"#fff"}}>We at, Super, very happy to announce launch of Internet Broadband Services on 
                           Underground Optic Fibre integrated with World-class Wireless 4G capable Base 
                           stations and also provide Landline services.</p>
                     </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 margin_bottom_30">
                     <div class="full">
                        <div class="footer_blog_2 width_9">
                           <h3>About US</h3>
                           <ul>
                           <li style={{padding:"2%"}}><a href="/" style={{color:"white"}}>Home</a></li>
                           <li style={{padding:"2%"}}><a href="/about" style={{color:"white"}}>About US</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-2 margin_bottom_30">
                     <div class="full">
                        <div class="footer_blog_2">
                           <h3>Product</h3>
                           <ul>
                           <li style={{padding:"2%"}}><a href="/plan" style={{color:"white"}}>Tariffs Plan</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 margin_bottom_30">
                     <div class="full">
                        <div class="footer_blog_2 width_9">
                           <h3>Support</h3>
                           <ul>
                           <li style={{padding:"2%"}}><a href="/contactus" style={{color:"white"}}>Contact US</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="footer_bottom">
            <p>Dessigned and developed by <strong>Superinfracon</strong></p>
         </div>
      </footer>
    </div>
  );
}
